'use strict';

import $ from 'jquery';
import Pubsub from 'pubsub-js';
import debounce from 'lodash/debounce';

var FooterAnimation = class {
	/*constructor(elem) {
		var secret = $('.secret-stuff');
		var gear = $('.rotator');
		var gearInner = $('#gear g');
		var bulb = $('svg#bulb g');
		var cord = $('svg#cord g');
		var connector = $('svg#connector g');
		var glow = $('img.glow');
		var whitelayer = $('.whitelayer');
		var footer = $('.footer');

	    // Tween lightbulb
	    var bulbAppear = TweenLite.to(bulb, 2, {
	    	css: {
	    		fill: '#ffffff',
	    		opacity: '1',
	    		className: '+=on',
	    		reversed:true
	    	},
	    	ease:Power2.easeIn,
	    	immediateRender: false,
	    	paused:true
	    });

	    // Tween connector
	    var connectorAppear = TweenLite.to(connector, 2, {
	    	css: {
	    		fill: '#ffffff',
	    		opacity: '1'
	    	},
	    	ease:Power2.easeIn,
	    	immediateRender: false,
	    	paused:true
	    });

	    // Tween gearInner
	    var gearInnerAppear = TweenLite.to(gearInner, 2, {
	    	css: {
	    		fill: '#ffffff',
	    		opacity: '1'
	    	},
	    	ease:Power2.easeIn,
	    	immediateRender: false,
	    	paused:true
	    });

	    // Tween cord
	    var cordAppear = TweenLite.to(cord, 2, {
	    	css: {
	    		fill: '#ffffff',
	    		opacity: '1'
	    	},
	    	ease:Power2.easeIn,
	    	immediateRender: false,
	    	paused:true
	    });

	    // Tween glow
	    var glowAppear = TweenLite.to(glow, 2, {
	    	css: {
	    		opacity: 1
	    	},
	    	ease:Power2.easeIn,
	    	immediateRender: false,
	    	paused:true,
	    });

	    Draggable.create(gear, {
	    	type: 'rotation',
	    	cursor: 'pointer',
	    	bounds: {
	    		minRotation: 0,
	    		maxRotation: 190
	    	}
	    });

    	var runFooterAnimations = {
			onDragEnd: function() {
				if(this.rotation == 190) {
					footer.addClass("on"),
					secret.addClass("on"),
					connector.addClass("on"),
					gearInner.addClass("on"),
					bulb.addClass("on"),
					cord.addClass("on"),
					gear.addClass("rotated")
				}
				else if(this.rotation < 190) {
					glow.removeClass("off"),
					footer.removeClass("on"),
					secret.removeClass("on"),
					connector.removeClass("on"),
					gearInner.removeClass("on"),
					bulb.removeClass("on"),
					cord.removeClass("on"),
					gear.removeClass("rotated")
				}
			},
			onDrag:function(){
				console.log(this.rotation),
				glow.removeClass("off"),
				glowAppear.progress(this.rotation/190)
			},
			onClick:function() {
				if(this.rotation < 190) {
					glow.removeClass("off"),
					TweenLite.to( gear, 1,{rotation:190}),
					footer.addClass("on"),
					secret.addClass("on"),
					connector.addClass("on"),
					gearInner.addClass("on"),
					bulb.addClass("on"),
					cord.addClass("on"),
					glow.addClass("off"),
					gear.addClass("rotated"),
					setTimeout(function(){
						footer.addClass("on"),
						secret.addClass("on")
					}, 1500);
				}
				else if(this.rotation == 190) {
					TweenLite.to( gear, 1,{rotation:0}),
					footer.removeClass("on"),
					secret.removeClass("on"),
					connector.removeClass("on"),
					gearInner.removeClass("on"),
					bulb.removeClass("on"),
					cord.removeClass("on"),
					gear.removeClass("rotated"),
					setTimeout(function(){
						footer.removeClass("on"),
						secret.removeClass("on")
					}, 1500);
				}
			}
		}

		//$(window).load(runFooterAnimations);
		//Pubsub.subscribe('smoothstate:page-change', runFooterAnimations);
	}*/
	constructor() {
		var secret = $('.secret-stuff');
	  var gear = $('.rotator');
	  var gearInner = $('#gear g');
	  var bulb = $('svg#bulb g');
	  var cord = $('svg#cord g');
	  var connector = $('svg#connector g');
	  var glow = $('img.glow');
	  var whitelayer = $('.whitelayer');
	  var footer = $('.footer');
		var footerNewsLetterInput = $('.footer-content').find('.news-letter-signup');

	  // Tween lightbulb
	  var bulbAppear = TweenLite.to(bulb, 2, {
	    css: {
	      fill: '#ffffff',
	      opacity: '1',
	      className: '+=on',
	      reversed:true
	    },
	    ease:Power2.easeIn,
	    immediateRender: false,
	    paused:true
	  });

	  // Tween connector
	  var connectorAppear = TweenLite.to(connector, 2, {
	    css: {
	      fill: '#ffffff',
	      opacity: '1'
	    },
	    ease:Power2.easeIn,
	    immediateRender: false,
	    paused:true
	  });

	  // Tween gearInner
	  var gearInnerAppear = TweenLite.to(gearInner, 2, {
	    css: {
	      fill: '#ffffff',
	      opacity: '1'
	    },
	    ease:Power2.easeIn,
	    immediateRender: false,
	    paused:true
	  });

	  // Tween cord
	  var cordAppear = TweenLite.to(cord, 2, {
	    css: {
	      fill: '#ffffff',
	      opacity: '1'
	    },
	    ease:Power2.easeIn,
	    immediateRender: false,
	    paused:true
	  });

	  // Tween glow
	  var glowAppear = TweenLite.to(glow, 2, {
	    css: {
	      opacity: .8
	    },
	    ease:Power2.easeIn,
	    immediateRender: false,
	    paused:true,
	  });

	  // // Tween glow
	  // var whitelayerAppear = TweenLite.to(whitelayer, 2, {
	  //   css: {
	  //     className: "+=on"
	  //   },
	  //   ease:Power2.easeIn,
	  //   immediateRender: false,
	  //   paused:true,
	  // });

	  // // Tween footer
	  // var footerAppear = TweenLite.to(footer, 2, {
	  //   css: {
	  //     background: "#ffffff"
	  //   },
	  //   ease:Power2.easeIn,
	  //   immediateRender: false,
	  //   paused:true,
	  // });

	  // // Tween easteregg
	  // var secretAppear = TweenLite.to(secret, 2, {
	  //   css:{
	  //       className: "+=on"
	  //   },
	  //   ease:Power2.easeIn,
	  //   immediateRender: false,
	  //   paused:true,
	  // });

	  Draggable.create(gear, {
	    type: 'rotation',
	    cursor: 'pointer',
	    bounds: {
	      minRotation: 0,
	      maxRotation: 190
	    },
	    // liveSnap:function(endValue) { return Math.round(endValue / 50) * 50; },
	    onDragEnd: function() {
	      if(this.rotation == 190) {
	        footer.addClass("on"),
	        secret.addClass("on"),
	        connector.addClass("on"),
	        gearInner.addClass("on"),
	        bulb.addClass("on"),
	        cord.addClass("on"),
	        gear.addClass("rotated")
					footerNewsLetterInput.fadeOut();
	      }
	      else if(this.rotation < 190) {
	        glow.removeClass("off"),
	        footer.removeClass("on"),
	        secret.removeClass("on"),
	        connector.removeClass("on"),
	        gearInner.removeClass("on"),
	        bulb.removeClass("on"),
	        cord.removeClass("on"),
	        gear.removeClass("rotated")
					footerNewsLetterInput.fadeIn();
	      }
	    },
	    onDrag:function(){
	      console.log(this.rotation),
	      glow.removeClass("off"),
	      glowAppear.progress(this.rotation/45)
	    },
	    onClick:function() {
	      if(this.rotation < 190) {
	      	console.log(this.rotation),
	        glow.removeClass("off"),
	        TweenLite.to( gear, 1,{rotation:190}),
	        footer.addClass("on"),
	        secret.addClass("on"),
	        connector.addClass("on"),
	        gearInner.addClass("on"),
	        bulb.addClass("on"),
	        cord.addClass("on"),
	        glow.addClass("off"),
	        gear.addClass("rotated"),
					footerNewsLetterInput.fadeOut();


	        setTimeout(function(){
	          footer.addClass("on"),
	          secret.addClass("on")
	        }, 1500);
	      }
	      else if(this.rotation == 190) {
	        TweenLite.to( gear, 1,{rotation:0}),
	        footer.removeClass("on"),
	        glow.addClass("off"),
	        secret.removeClass("on"),
	        connector.removeClass("on"),
	        gearInner.removeClass("on"),
	        bulb.removeClass("on"),
	        cord.removeClass("on"),
	        gear.removeClass("rotated"),
					footerNewsLetterInput.fadeIn();

	        setTimeout(function(){
	          footer.removeClass("on"),
	          secret.removeClass("on")
	        }, 1500);
	      }
	    }
	  });
	}
}

export default FooterAnimation;
