'use strict';

import $ from 'jquery';

var Quiz = class {

  constructor(quizControls, dataHolders, hints, settings) {

    //mutable properties
    this._totalQuestion = undefined;
    this._curentQuestion = 1;
    this._corectAnswers = 0;
    //seted properties
    this._controls = quizControls;
    this._holders = dataHolders;
    this._hints = hints;
    this._settings = settings;


    this.init();
  }

  init() {
    this.getTotalQuestion();
    this.setTotalQuestion();

    this.checkForCorectAnswer();
    this.selectAnswer();
    this.nextQuestion();

    this.changeBtnLabel(this.getLastQuestion().find(this._controls.nextButton), 'Resultat');
    this.tryAgain();
  }

  getTotalQuestion() {
    this._totalQuestion = this._settings.questions.length;
  }

  setTotalQuestion() {
    this._holders.totalQuestion.text(this._totalQuestion);
  }

  isAnswerCorect(choice) {
    var isCorect = choice.data('corect');

    return isCorect;
  }

  checkForCorectAnswer() {
    var self = this;

    this._controls.submitButton.click(function(){

      var hintToShow = $(this).parent().parent().next(),
          answerToCheck = $(this).parent().parent().find('.answer-selected');

      $(this).hide();

      self.blockAnswering(self._settings.answers);

      self.markAnswers(self.isAnswerCorect(answerToCheck), answerToCheck);
      self.showHints(self.isAnswerCorect(answerToCheck), hintToShow);

      if ( self.isAnswerCorect(answerToCheck) ) {
        self._corectAnswers += 1;
      }
      self.updateCorectAnswers();
    });
  }

  selectAnswer() {
    var self = this;

    this._settings.answers.click(function(){
      var selectedAnswer = $(this);

      self._settings.answers.removeClass('answer-selected');
      selectedAnswer.addClass('answer-selected');

      self.allowSubmit();

    });
  }

  showSubmit() {
    this._holders.submitHolder.show();
  }

  allowSubmit() {
    this._controls.submitButton.prop('disabled', false);
  }

  doNotAllowSubmit() {
    this._controls.submitButton.prop('disabled', true);
  }

  nextQuestion() {
    var self = this;

    this._controls.nextButton.click(function(){
      var currentQuestion = $(this).parent().parent().parent();

      self._curentQuestion += 1;

      if ( !self.isQuizFinish() ) {
        self.trackProgress();
      }

      self.allowAnswering(self._settings.answers);
      currentQuestion.hide();
      currentQuestion.next().show();

      self.hideHints();
      self.doNotAllowSubmit();
    });
  }

  blockAnswering(answers) {
    answers.prop('disabled', true);
  }

  allowAnswering(answers) {
    answers.prop('disabled', false);
  }

  markAnswers(isCorect, answer) {
    if (isCorect) {
      answer.addClass('answer-corect');

      return;
    }
    answer.addClass('answer-wrong');
  }

  showHints(isCorect, hint) {

    hint.show();

    if (isCorect) {
      this._hints.corect.show();

      return;
    }
    this._hints.wrong.show();
  }

  hideHints() {
    this._hints.wrapper.hide();
    this._hints.globalSelector.hide();
  }

  trackProgress() {
    this._holders.curentQuestion.text(this._curentQuestion);
  }

  isQuizFinish() {
    if (this._curentQuestion > this._totalQuestion) {
      return true;
    }
    return false;
  }

  changeBtnLabel(btn, label) {
    btn.text(label);
  }

  getLastQuestion() {
    var lastQuestion = this._settings.questions.last();

    return lastQuestion;
  }

  updateCorectAnswers() {
    this._holders.result.text(this._corectAnswers);
  }

  resetQuiz() {
    this._curentQuestion = 1;
    this._corectAnswers = 0;

    this._holders.curentQuestion.text(this._curentQuestion);

    this.hideHints();

    this._settings.answers.removeClass('answer-corect answer-wrong answer-selected');
  }

  tryAgain() {
    var self = this;

    this._controls.tryAgainButton.click(function(){
      self.resetQuiz();

      self._holders.resultWrapper.hide();
      self._settings.questions.first().show();
      self._controls.submitButton.show();
      self.showSubmit();
    });
  }

}

export default Quiz;
