'use strict';
import $ from 'jquery';

var ShowMoreList = class {
  constructor(btn, items) {
    this._button = btn;
    this._items = items;

    this.init();
  }

  init() {
    this.showMore();
  }

  showMore() {
    var self = this;

    self._button.click(function(e){
      e.preventDefault();

      self.removeBtn();

      self._items.removeClass('hide');
    });

  }

  removeBtn() {
    var self = this;

    self._button.hide();
  }


};

export default ShowMoreList;
