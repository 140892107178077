'use strict';
// import $ from 'jquery';

import Smoothstate from '../vendor/jquery.smoothState.min';

import Newsletter from '../components/newsletter.js';
import Webshop from '../components/webshop';
import Barometar from '../components/barometar';
import InteractiveMap from '../components/interactive-map';
import IsotopeGrid from '../components/isotopeGrid';
import Carousel from '../components/carousel';
import InstagramPopulate from '../components/instagramPopulate';
import FooterAnimation from '../components/footerAnimation';
// import Underscore from '../vendor/underscore';
import Hero from '../components/hero';
import Quiz from '../components/quiz';
import randomTeaser from '../components/randomTeaserFlip';
import ShowMoreList from '../components/showMore';
import SecondaryNavigation from '../components/secondaryNavigation';
// import GlobalAnimator from './globalAnimator';
//initialize all global components
import globalNavigation from './globalNavigation';


export default () => {
    globalNavigation();
    // var globalAnimator = new GlobalAnimator();

    //Initialize Newsletter
    var mailChimpNewsletter = new Newsletter();

    //initialize all secondary navigations
    var secondaryNavigationElems = document.querySelectorAll('.secondary-navigation');
    var secondaryNavigations = [];
    for (var j = 0; j < secondaryNavigationElems.length; j++) {
        secondaryNavigations.push(new SecondaryNavigation(secondaryNavigationElems[j]));
    }

//to turn on web shop uncomment lines bellow, also see isotopeGrid.js and uncoment webshop part also

    var webshop = new Webshop();
    webshop.getData(webshop.latestArticleAppend);
    webshop.getData(webshop.webShopAppend);
    webshop.getData(webshop.calendarAppend);

    //initialize all Isotope grids
    $(window).on('load', function () {
        var isotopeElems = document.querySelectorAll('[data-js-section="isotope-grid"]');
        var isotopeGrids = [];
        for (var j = 0; j < isotopeElems.length; j++) {
            isotopeGrids.push(new IsotopeGrid(isotopeElems[j]));
        }
    });

    //initialize Instagram feed
    var instafeedPopulate = new InstagramPopulate();

    //initialize all Carousels
    var carouselElems = document.querySelectorAll('[data-js-module="image-carousel"]');
    var carousels = [];
    for (var j = 0; j < carouselElems.length; j++) {
        carousels.push(new Carousel(carouselElems[j]));
    }

    //initialize all hero components
    var heroElems = document.querySelectorAll('[data-js-section="hero"]');
    var heros = [];
    for (var j = 0; j < heroElems.length; j++) {
        heros.push(new Hero(heroElems[j]));
    }

    //initialize InteractiveMap
    if (typeof exhibitionsJSON !== 'undefined') {
        var map = new InteractiveMap();
    }
    //initialize barometar
    var barometarChart = new Barometar();

    //initialize quiz
    var quizControls = {
        nextButton: $('.next-question'),
        submitButton: $('.submit-answer'),
        tryAgainButton: $('.repeat-quiz')
    },
            quizDataHolders = {
                totalQuestion: $('.number-of-question'),
                curentQuestion: $('.current-question'),
                result: $('.corected-answered'),
                resultWrapper: $('.result-wrapper'),
                submitHolder: $('.submit-holder')
            },
            quizHints = {
                corect: $('.answer-corect-hint'),
                wrong: $('.answer-wrong-hint'),
                globalSelector: $('.hint'),
                wrapper: $('.question-hints')
            },
            quizSetting = {
                questions: $('.question-wrapper'),
                answers: $('.answer')
            }


    var quiz = new Quiz(quizControls, quizDataHolders, quizHints, quizSetting);

    // initialize showMore
    var showMoreBtn = $('.show-more'),
            itemParent = $('.show-more').siblings('.for-show-more.hide'),
            itemToShow,
            showMoreExhibitions;
    if (showMoreBtn.length === 1) {
        itemToShow = $('.for-show-more.hide');
        showMoreExhibitions = new ShowMoreList(showMoreBtn, itemToShow);
    } else {
        showMoreBtn.each(function (i, v) {
            itemToShow = $(v).closest('.content-section').find('.for-show-more.hide');
            showMoreExhibitions = new ShowMoreList($(v), itemToShow);
        });
    }

    //initialize random teaser
    var exibitionTeaser = $('.for-show-more:not(.hide)').find('.thumbnails-exhibition'),
            flipClasses = ['tricky2D'];

    var exibitionRandomTeaserFlip = new randomTeaser(exibitionTeaser, flipClasses);

    var footerAnimation = new FooterAnimation();
};
