import RefreshDilemma from './dilemma/refreshDilemma.js';
import SingleDilemmaHandle from './dilemma/singleDilemma.js';
import dilemmaCarousels from './dilemma/dilemmaCarousels.js';

export default () => {
  dilemmaCarousels();

  let backToLandingPage = new RefreshDilemma({
    starterButton: $('.start-dilemma-btn'),
    iddleLImit: 59,
    chartType: 'kiosk'
  });

  let SingleDilemma = new SingleDilemmaHandle({
    selectors: {
      answers: $('.dilemma-single-answers-exp .answers'),
      submitBtn: $('.dilemma-submit-btn-exp'),
      dilemmaWrapper: $('main'),
      chartType: 'kiosk'
    }
  })

  SingleDilemma.enableSubmit();
  SingleDilemma.handlePopups();
  SingleDilemma.submitAnswer();

  let SingleDilemmaWEB = new SingleDilemmaHandle({
    selectors: {
      answers: $('.answers .answer-single'),
      submitBtn: $('.dilemma-submit-btn'),
      dilemmaWrapper: $('.dilemma-single'),
      chartType: 'web'
    }
  })


  SingleDilemmaWEB.enableSubmit();
  SingleDilemmaWEB.handlePopups();
  SingleDilemmaWEB.submitAnswer();

}
