'use strict';

import $ from 'jquery';
import Pubsub from 'pubsub-js';
import debounce from 'lodash/debounce';

var Hero = class {
  constructor(elem) {
    var heroTopImage = $(elem).find('.hero__top'),
        windowHeight = $(window).height(),
        windowWidth = $(window).width(),
        mobileWIdth = 639,
        videoInHero = $('.video-hero-for-play')[0],
        playVideoBtn = $('.play-video');

    //$(heroTopImage).height($(heroTopImage).height());

    var runAnimations = () => {
      var textFadeInDuration = 400;
      var textElements = elem.querySelectorAll('.hero__header span').length;
      var textFadeInWaitTime = (textElements+1) * textFadeInDuration;

      //var heroContentAnimationDuration = 550;
      //var heroContentElem = elem.querySelector('.hero__content');

      setTimeout(() => {
        elem.classList.add('hero--animate-text-in');

        setTimeout(() => {
          //if the hero has a content section, animate that in before splitting the text
          //if (false) {
          //  elem.classList.add('hero--animate-content');
          //  setTimeout(() => {
          //    elem.classList.add('hero--animate-text-split');
          //  }, heroContentAnimationDuration*2);
          //} else {
            elem.classList.add('hero--animate-text-split');
          //}
        }, textFadeInWaitTime);
      }, 1200);
    }

    //when the document is ready, initialize the hero animation.
    $(window).load(runAnimations);

    // var removeFixedHeight = () => {
    //   $(heroTopImage).height('auto');
    // };

    var calcPerc = (percent, number) => {
      return percent * number / 100;
    }

    var setHeightToHero = () => {
      heroTopImage.height(calcPerc(80, windowHeight))
    }

    setHeightToHero();

    // var resizeRemoveFixedFlag = true;
    // var w = $( window ).width();
    // var onResizeDone = debounce(() => {
    //   resizeRemoveFixedFlag = true;
    //   $(heroTopImage).height($(heroTopImage).height());
    // }, 200);
    //
    // //when resizing, remove the set hero height and reset it when done resizing
    // window.addEventListener('resize', () => {
    //   //only if window is resized horizontally
    //   if (resizeRemoveFixedFlag && w != $( window ).width()) {
    //     removeFixedHeight();
    //     resizeRemoveFixedFlag = false;
    //   }
    //
    //   onResizeDone();
    // });

    window.addEventListener('resize', () => {
      windowHeight = $(window).height();

      setHeightToHero();
    });

    //or when recieving a 'smoothstate:page-change' event
    Pubsub.subscribe('smoothstate:page-change', runAnimations);

    //video play for mobile handle
    var stopVideoOnMobile = (video) => {
      if (windowWidth <= mobileWIdth && video !== undefined) {
        video.pause();
      }
    }

    var playVideoOnMobile = (video, btn) => {
      var isPlaying = false;
      btn.click(function(){
        if (isPlaying) {
          video.pause();
          isPlaying = false;
          $(this).text('Se video');
          return;
        }

        video.play();
        $(this).text('Stop video');
        isPlaying = true;
      })
    }

    stopVideoOnMobile(videoInHero);
    playVideoOnMobile(videoInHero, playVideoBtn);

  }
};

export default Hero;
