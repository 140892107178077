export default () => {
  // let dilemmaSLider = $('.dilemma-slider'),
  //     dilemmaItemWrapper = $('.dilemma-item-wrapper'),
  //     navigationArrows = document.querySelectorAll('.slider-navigation .navigation'),
  //     slickSettings = {
  //       autoplay: true,
  //       autoplaySpeed: 5000,
  //       fade: true,
  //       nextArrow: $('.slider-navigation .right'),
  //       prevArrow: $('.slider-navigation .left'),
  //       speed: 800
  //     }
  //
  // //Animating slide one by one after slide/swipe/fade
  // var animateDilemmaTitle = function(event, slick, currentSlide) {
  //   let slideIndex = currentSlide;
  //
  //   dilemmaItemWrapper.eq(currentSlide).addClass('hero--animate-text-in');
  //
  //   setTimeout(() => {
  //     dilemmaItemWrapper.eq(currentSlide).addClass('hero--animate-text-split');
  //     dilemmaItemWrapper.find('img').removeClass('blure-me');
  //   }, 600)
  //
  // }
  //
  // //Animating fist slide
  // var animateDilemmaTitleInit = function() {
  //   dilemmaItemWrapper.first().addClass('hero--animate-text-in');
  //
  //   setTimeout(() => {
  //     dilemmaItemWrapper.first().addClass('hero--animate-text-split');
  //   }, 600)
  // }
  // //Reseting animation before sliding to next animation
  // var resetAnimation = function () {
  //   dilemmaItemWrapper.removeClass('hero--animate-text-in hero--animate-text-split');
  //
  //   dilemmaItemWrapper.find('img').addClass('blure-me');
  // }
  //
  // //Initializing dilemma titles animations
  // dilemmaSLider.on('init', animateDilemmaTitleInit);
  // dilemmaSLider.on('afterChange', animateDilemmaTitle);
  // dilemmaSLider.on('beforeChange', resetAnimation);
  //
  // //initialize slick slider
  // dilemmaSLider.slick(slickSettings);

  //initialize slick slider for dillema-exp
  if ($('.slider-wrapper-exp').length > 0) {
    $('.slider-wrapper-exp').slick({
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: false,
      fade: true
    });
  }


  //initialize slick slider on categories page
  $('.slider-archive-exp').each(function(){
    var slickInduvidual = $(this);

    function sliderInView() {
      if (slickInduvidual.get(0).getBoundingClientRect().top < $(window).height() - 50) {
        return true;
      }
      return false;
    }

    slickInduvidual.on('init', function(slick){
      if (sliderInView()) {
        slickInduvidual.addClass('slide-in');
      }

      $(window).scroll(function(){
        if (sliderInView()) {
          slickInduvidual.addClass('slide-in');
        }
      })
    });

    if (slickInduvidual.length > 0) {
      slickInduvidual.slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: slickInduvidual.next().find('.right'),
        prevArrow: slickInduvidual.next().find('.left')
      });
    }


  })


  //Arrows shaking handle
  // for(var i=0; i<navigationArrows.length; i++){
  //  navigationArrows[i].onclick = function(){
  //   let arrow = this.querySelector('.arrow');
  //
  //   this.classList.remove("shake");
  //
  //   void this.offsetWidth;
  //
  //   this.classList.add("shake");
  //  }
  // }

}
