var Barometar = class {
//those prop will be accessible by all methods
    constructor() {
        this.someProp = undefined;
        //If want to call all methods when instantiating leave this here, if not every method will needed to be called seperatly after instantiation.
        if(typeof barometarData !== 'undefined') {
            this.init();
        }
    }

    init() {
        this.getData();
        this.resetMap();
        this.etc();
        var chartCount = $('.chart').length, barCount = $('.period').length;
        var ww = $(window).width();
        if (ww > 990) {
            $('.period').css({width: (ww - 60 - (chartCount - 1) * 50) / barCount});
        } else {
            $('html, body').animate({scrollTop: $('.active .day').offset().top - $('.active .day').height() / 2 - $(window).height() / 2});
        }
    }

    getData() {
        this.data = barometarData;
        $.each(this.data, function (i, v) {
            var dayChart = $('<div/>')
                    .addClass('chart chart-' + (i + 1));
            $('.chart-wrap').append(dayChart);
            var trimPadding = [];
            $.each(v.hours, function (ii, vv) {
                trimPadding.push(+vv.crowd);
                var activeClass = '';
                if (vv.active) {
                    activeClass = ' active';
                    dayChart.addClass('active');
                    $('.barometar .subtitle').html(vv.message);
                }
                var period = $('<div/>')
                        .addClass('period period-' + (ii + 1) + activeClass + ' crowd-' + vv.crowd);
                dayChart.append(period);
                var bar = $('<div/>')
                        .addClass('bar')
                        .html(vv.crowd_name);
                period.append(bar);
                var interval = $('<div/>')
                        .addClass('interval')
                        .html(vv.interval);
                period.append(interval);
            });
            console.log(trimPadding);
            dayChart.addClass('trim-' + (4 - Math.max.apply(null, trimPadding)));
            var day = $('<div/>')
                    .addClass('day')
                    .html(v.day);
            dayChart.append(day);
        });
    }

    resetMap() {

    }

    etc() {

    }
}
export default Barometar;
