'use strict'

import Pubsub from 'pubsub-js';
import Throttle from 'lodash/throttle';

var GlobalHeader = class {
  constructor(elem) {
    let offCanvasMenuToggleBtn = document.querySelector('[data-js="toggle-off-canvas"]');
    this.offCanvasMenuToggleBtn = offCanvasMenuToggleBtn;
    let secondaryMenuToggleBtn = document.querySelector('.global-navigation__secondary-nav .menu-toggler');
    let searchToggleBtn = document.querySelector('.global-navigation__search .search-btn');

    let secMenuOpen, searchOpen = false;

    //Add event listener to the off canvas menu toggle button
    offCanvasMenuToggleBtn.addEventListener('click', () => {
      Pubsub.publish('globalHeader:toggle-off-canvas');
      document.querySelector('.mega-menu-toggle ').classList.add('mega-menu-open');
    });

    //Toggle the secondary menu when button is clicked
    secondaryMenuToggleBtn.addEventListener('click', () => {
      //if search is open, close it
      if (searchOpen) {
        elem.classList.remove('global-navigation--search-open');
        searchOpen = false;
      }

      //toggle the secondary menu open/closed
      if (!secMenuOpen) {
        elem.classList.add('global-navigation--sec-nav-open');
        secMenuOpen = true;
      } else {
        elem.classList.remove('global-navigation--sec-nav-open');
        secMenuOpen = false;
      }
    });

    //Toggle the search when button is clicked
    searchToggleBtn.addEventListener('click', () => {
      //if secondary menu is open, close it
      if (secMenuOpen) {
        elem.classList.remove('global-navigation--sec-nav-open');
        secMenuOpen = false;
      }

      //toggle the search open/closed
      if (!searchOpen) {
        elem.classList.add('global-navigation--search-open');
        searchOpen = true;
      } else {
        elem.classList.remove('global-navigation--search-open');
        searchOpen = false;
      }
    });

    //Add window scroll listener to condense the header
    var checkCondensed = () => {
      if (window.scrollY > 0) {
        elem.classList.add('global-navigation--condensed');
        Pubsub.publish('globalHeader:toggle-condensed', true);
      } else {
        elem.classList.remove('global-navigation--condensed');
        Pubsub.publish('globalHeader:toggle-condensed', false);
      }
    };
    checkCondensed();
    window.addEventListener('scroll', Throttle(checkCondensed, 50, {'leading': true}));
  }

  burgerToCross(toCross) {
    if (toCross) {
      this.offCanvasMenuToggleBtn.classList.add('burger--to-cross');
    } else {
      this.offCanvasMenuToggleBtn.classList.remove('burger--to-cross');
    }
  };
};

export default GlobalHeader;
