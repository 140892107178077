'use strict';
import $ from 'jquery';
import Helper from './helper.js';

var helper = new Helper();

var SecondaryNavigation = class {
  constructor(elem) {

    var secondLevel = $(elem).find('.level-title'),
        thirdLevel = $('.level-children'),
        arrowMenuToggler = $('.icon--arrow--down');

    //If user is on touch device
    if (helper.isTouch()) {
      secondLevel.click(function(){
        $(this).parent().toggleClass('open');
        // console.log($(this).parent().parent());
      })

      return;
    }
    //If user is on desktop
    //close menu if the window is clicked
    // $(window).on('click', () => {
    //   $(elem).find('.secondary-navigation__item').removeClass('open');
    // });


    // secondLevel.hover(function(){
    //   $(this).parent().toggleClass('open');
    // });

    // thirdLevel.hover(function(){
    //   $(this).parent().toggleClass('open');
    // });

  }
};

export default SecondaryNavigation;
