// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

//import $ from 'jquery';
import Pubsub from 'pubsub-js';
import global from './global/global';
import svg4everybody from './vendor/svg4everybody.min.js';

import throttle from 'lodash/throttle';
import forEach from 'lodash/forEach';

import dilemma from './dilemma';

svg4everybody();

$(() => {
  //if user is on dilemma page, load only scripts for dilemma
  if ($('body').hasClass('dilemma-exp') || $('body').hasClass('dilemma-single-exp') || $('body').hasClass('dilemma-categories-exp')) {
    dilemma();
    return;
  }

  //initialize all global code and contr
  global();

  dilemma();

//initialize Smoothstate page transitions
var $page = $('#smoothState'),
  options = {
      debug: true,
      prefetch: true,
      cacheLength: 2,
      // onBefore: () => {
      //   Pubsub.publish('globalHeader:toggle-off-canvas');
      // },
      anchors: 'a:not(".fancybox")',
      onStart: {
        duration: 250, // Duration of our animation
        render: function ($container) {
          // Add your CSS animation reversing class
          $container.addClass('is-exiting');
          // Restart your animation
          smoothState.restartCSSAnimations();
        }
      },
      onReady: {
        duration: 0,
        render: function ($container, $newContent) {
          // Remove your CSS animation reversing class
          $container.removeClass('is-exiting');
          // Inject the new content
          $container.html($newContent);

          // fix for not animating on first load with smooth state, need to call it here also
          var checkElementsInView = function () {
            var windowHeight = $(window).height();
            var checkItems =  $('.scroll-animate').not('.animate-in');
            forEach(checkItems, function (pt) {
              //if a teaser element is within screen view, add the 'fade-in' class to it.
              if (pt.getBoundingClientRect().top < windowHeight - 50) {
                $(pt).addClass('animate-in');
              }
            });
          };

          checkElementsInView();

        }
      },
      onAfter: () => {
      //remove any 'slideout-open' class that might be on the body tag.
      global();
      Pubsub.publish('smoothstate:page-change');
      }
};

//if window is bigger then 1023(tablet and mobile) then let smootstate do its job
var windowWidth = $(window).width();
var widthToStartSmooth = 1023;

if (windowWidth > 1023) {
  //var smoothState = $page.smoothState(options).data('smoothState');
}






//for iPhone specifix fixes (e.g. lack of flexbox support for Safari prior to 9.1)
if (navigator.userAgent.match(/iP(hone|od|ad)/i)) {
  $('body').addClass('browser-ios');
}
//safari detect
if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
  $('body').addClass('browser-safari');
}
//edge detect
if (window.navigator.userAgent.indexOf("Edge") > -1) {
  $('body').addClass('browser-edge');
}


function getInternetExplorerVersion() {
  var rv = -1;
  if (navigator.appName == 'Microsoft Internet Explorer') {
    var ua = navigator.userAgent;
    var re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
    if (re.exec(ua) != null)
      rv = parseFloat(RegExp.$1);
  }
  else if (navigator.appName == 'Netscape') {
    var ua = navigator.userAgent;
    var re = new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})");
    if (re.exec(ua) != null)
      rv = parseFloat(RegExp.$1);
  }
  return rv;
}

if (getInternetExplorerVersion() == 11) {
  $('body').addClass('ie11');
}

$('.gears').click(function () {
  $('.gears, .gear, .rotator, #gear_big').toggleClass('on');

  if ($('.bulb-input-2.checked').length > 0) {
    $('.bulb-input-2.checked').removeClass('checked');
    $('.bulb-input-1').addClass('checked');
    $('.blink').addClass('on');

    setTimeout(function () {
      $('.secret-stuff').addClass('shown');
      $('.footer').addClass('secret');
    }, 1000);
  }

  else {
    $('.bulb-input-1').removeClass('checked');
    $('.bulb-input-2').addClass('checked');
    $('.secret-stuff').removeClass('shown');
    $('.footer').removeClass('secret')
    $('.blink').removeClass('on');
  }

  $('input[name=switch]').click(function () {
    setTimeout(function () {
      $('.secret-stuff').toggleClass('shown');
    }, 1000);
  });

  $('.close').click(function () {
    $('.secret-stuff').removeClass('shown');
  });


});

//removing anchor borders from inside wysiwyg when img is wrapped ith anchor
var anchorInWysiwyg = $('.wysiwyg').find('a');
anchorInWysiwyg.has('img').css({
  border: 'none'
})


$(document).ready(function() {
  $('.cookieBar-section').cookieBar({ closeButton : '.cookie-close-button' });
  $(".fancybox").fancybox({
    afterLoad: function() {
      var imageCaption = this.title,
          downLoadTmpl = '<a href="'+ this.element.data('hd-image') + '" class="download-image" target="_blank"></a>',
          fancyBoxWrapper = $('.fancybox-skin'),
          hdImagePresent = this.element.data('hd-image').length > 0;

      if (hdImagePresent) {
        fancyBoxWrapper.prepend(downLoadTmpl);
      }

    }
  });



  $('.load-more-posts').on('click', function(event) {
    event.preventDefault();

    var offset = $(this).data('offset');
    var search_term = $(this).data('searchterm');
    var ajax_url = $(this).data('url');

    $.ajax({
      type: 'GET',
      url: ajax_url,
      dataType: 'json',
      data: {
        'action': 'exp_load_search',
        'offset': offset,
        'search_term' : search_term
      },
      success: function(response) {
        console.log(response);
        if (response.count > 0) {
          $('.pull-up-over').append(response.html);
          if (response.count < 20) {
            $('.load-more-posts').hide();
          }
        } else {
          $('.load-more-posts').hide();
        }
        $('.load-more-posts').data('offset', (offset+20));
      },
      error: function(error) {
        console.log(error);
      },
      complete: function(complete) {
      }
    })
  });

});

// $('.read-more-wrap .btn').on('click', function () {
//   $('.discover-exhibition.hide').removeClass('hide');
// });

if ( ! Modernizr.objectfit ) {
  $('.hero__background').each(function () {
    var $container = $(this),
    imgUrl = $container.find('img').prop('src');
    if (imgUrl) {
      $container
        .css('backgroundImage', 'url(' + imgUrl + ')')
        .addClass('compat-object-fit');
    }
  });
}



});



