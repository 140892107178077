//After user idle for some time, reload landing page of dilemma
var RefreshDilemma = class {
  constructor(settings) {
    this.starterButton = settings.starterButton;
    this.iddleLImit = settings.iddleLImit;

    this.idleTime = 0;

    this.init();
  }

  init() {
    this.localStorageSet();
    this.setHomeUrl(this.starterButton);
    if (this.onSingleDilemma() && this.localStorageSet()) {
      this.returnToHomePage();
      this.resetIddleTime();
    }
  }

  localStorageSet() {
    if (localStorage.getItem("homeUrl-dilemma-reset") === null) {
      return false;
    }
    return true;
  }

  onSingleDilemma() {
    if ($('body').hasClass('dilemma-single-exp') || $('body').hasClass('dilemma-categories-exp')) {
      return true;
    }
    return false;
  }

  setHomeUrl(starterButton) {
    let _this = this;

    starterButton.click(function(event){
      localStorage.setItem("homeUrl-dilemma-reset", window.location.href);
    })
  }

  resetIddleTime() {
    let _this = this;

    document.onmousemove = function(){_this.idleTime = 0};
    document.onmousedown = function(){_this.idleTime = 0};
    document.ontouchstart = function(){_this.idleTime = 0};
    document.onclick = function(){_this.idleTime = 0};
  }

  returnToHomePage() {
    let _this = this;

    var idleInterval = setInterval(function(){
      _this.idleTime ++;
      if (_this.idleTime >= _this.iddleLImit) {
          let homeUrl = localStorage.getItem("homeUrl-dilemma-reset");
          window.location.href = homeUrl;
      }
    }, 1000);

  }
}

export default RefreshDilemma;
