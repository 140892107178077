'use strict';

var NewsletterHandle = class {
  constructor() {
    var submitBtn = $('.input-group').find('input[type="submit"]'),
        errorToShow,
        btnThatClicked;

    var mailErrorHandle = function(response) {


      var responseTitle = response.data.title,
          responseStatus = response.success,
          responseMsg = response.data.message;


      if (responseStatus) {
        errorToShow = '.valid';
      } else {

        if (responseTitle === 'Member Exists') {
          errorToShow = '.exists';
        } else if (responseTitle === 'Invalid Resource') {
          errorToShow = '.invalid';
        } else if (responseMsg === 'Please enter your e-mail') {
          errorToShow = '.no-email';
        } else if (responseMsg === 'Agree is needed') {
          errorToShow = '.agree';
        } else {
          errorToShow = '.false';
        }

      }

      $(btnThatClicked).parent().parent().parent().find(errorToShow).fadeIn();
    }

    var ajaxSubmit = function(e) {
      e.preventDefault();

      var emailHolder = $(this).parent().parent().find('input[type="email"]'),
          listNameHolder = $(this).parent().parent().find('input[name="list_name"]'),
          agreeHolder = $(this).parent().parent().parent().find('input[name="email_agree"]'),
          errorsHolder = $('.newsletter-error-handle');

      btnThatClicked = $(this);

      var email = emailHolder.val(),
          listName = listNameHolder.val(),
          agree = agreeHolder.is(':checked'),
          nonce = listNameHolder.next().val();

      errorsHolder.hide();

      $.ajax({
           type: "POST",
           url: "/wp-admin/admin-ajax.php?action=exp_mc_subscribe",
           data: {
               email: email,
               list_name: listName,
               agree : agree,
               nonce: nonce
           }
       }).done(mailErrorHandle);

    }

    submitBtn.click(ajaxSubmit);
  };
};

export default NewsletterHandle;
