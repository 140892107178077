'use strict';

import Pubsub from 'pubsub-js';
import Slideout from 'slideout';
import GlobalHeader from '../components/globalHeader';

export default () => {
  var globalHeaderElem = document.querySelector('.global-navigation');
  var globalHeader = new GlobalHeader(globalHeaderElem);

  //initialize the slideout navigation
  var offCanvasWidth = window.outerWidth;
  // offCanvasWidth = Math.min(offCanvasWidth, 100);
  var offCanvasMenuElem = document.querySelector('.off-canvas-menu');
  // offCanvasMenuElem.style.width = offCanvasWidth + 'px';

  var slideout = new Slideout({
    'panel': document.querySelector('.main'),
    'menu': offCanvasMenuElem,
    'padding': offCanvasWidth,
    'tolerance': 70,
    'duration' : 450,
    touch: false,
    side: 'right',
    'easing': 'cubic-bezier(.32,2,.55,.27)'
  });

  //when the slideout has opened, toggle the off canvas btn to a close icon
  slideout.on('beforeopen', () => {
    globalHeader.burgerToCross(true);
  });
  slideout.on('beforeclose', () => {
    globalHeader.burgerToCross(false);
  });

  //add event listener to globalHeader events
  Pubsub.subscribe('globalHeader:toggle-off-canvas', () => {
    slideout.toggle();
  });

  //When the global header toggles condensed style, apply style to main to compensate
  var navLayoutElem = document.querySelector('.navigation-layout');
  var secNavLayoutElem = document.querySelector('.secondary-navigation');
  Pubsub.subscribe('globalHeader:toggle-condensed', (on, condensed) => {
    if (condensed) {
      navLayoutElem.classList.add('menu-condensed');
      if (typeof(secNavLayoutElem) != 'undefined' && secNavLayoutElem != null) {
        secNavLayoutElem.classList.add('secMenu-condensed');
      }
    } else {
      navLayoutElem.classList.remove('menu-condensed');
      if (typeof(secNavLayoutElem) != 'undefined' && secNavLayoutElem != null) {
        secNavLayoutElem.classList.remove('secMenu-condensed');
      }
    }
  });
}
