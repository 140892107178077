var SingleDilemma = class {
  constructor(settings) {
    this.answersSelector = settings.selectors.answers;
    this.submitBtn = settings.selectors.submitBtn;
    this.dilemmaWrapper = settings.selectors.dilemmaWrapper;
    this.chartType = settings.selectors.chartType;

    this.vpWidth = $(window).width();

    this.selectedAnswer = undefined;
    this.selectedAnswerIndex = undefined;
    this.selectedAnswerPercent = undefined;
  }

  ifElementInView(elm, offset) {
    if (elm.get(0).getBoundingClientRect().top < $(window).height() - offset) {
      return true;
    }
    return false;
  }

  limitCharacters(string, limitTo) {
    return string.substring(0, limitTo) + '...';
  }

  enableSubmit(){
    let _this = this;

    _this.answersSelector.click(function(){

      _this.selectedAnswerIndex = _this.answersSelector.index($(this));
      _this.selectedAnswer = $(this).data('answer-text');

      _this.answersSelector.removeClass('selected');
      $(this).addClass('selected');
      _this.submitBtn.attr('disabled', false);
    })
  }

  submitAnswer() {
    let _this = this;

    function setPopulation(populationNumber) {
      let populationHolder = $('.population .number');

      populationHolder.text(populationNumber)
    }

    function handleAnimationOnSubmit(chartData) {
      _this.dilemmaWrapper.addClass('answer-submited');
      _this.drawChart(chartData);
    }
    function sendAnswer() {
      let btn = $(this);

      let dilemmaId = $('.dilemma-single-both').data('id'),
          dilemmaUrl = $('.dilemma-single-both').data('url'),
          dilemmaAnswer = $('.answers-both').index($('.answers-both.selected'));

      btn.attr('disabled', true);
      $('.see-more-below').slideDown();
        $.ajax({
          method: "POST",
          url: dilemmaUrl,
          dataType: 'json',
          data: {
            action: "submit_dilema_answer",
            dilema_id: dilemmaId,
            answer_id: dilemmaAnswer
          }
        }).done(function(response){

          _this.selectedAnswerPercent = response.responseData.chartData[_this.selectedAnswerIndex];

          _this.populateSelectedAnswer();

          //limiting number of characters in name prop...
          response.responseData.chartData.forEach(function(object){
            object.name = _this.limitCharacters(object.name, 32);
          })

          handleAnimationOnSubmit(response.responseData.chartData);
          setPopulation(response.responseData.population);


          let elmForAnimateIn = $('.dilemma-web-animate-me-in');

          if (elmForAnimateIn.length > 0) {
            _this.animateInElmnts(elmForAnimateIn);
          }

        }).fail(function(){
          console.log('faild to load resorces');
        });
    }

    _this.submitBtn.click(sendAnswer);
  }

  handlePopups() {
    let openBtn = $('.open-popup-dilemma'),
        closeBtn = $('.close-popup-dilemma'),
        popuOverlay = $('.dilemma-popups-exp'),
        popuOverlaySingle = $('.dilemma-popups-exp .single-popup');

        function openPopup() {
          $('.global-navigation').css({
            'z-index': '-1'
          });
          let popupToOpen = $(this).data('popup');

          popuOverlay.addClass('open');
          $(popupToOpen).addClass('open');
        }

        function closePopup() {
          $('.global-navigation').css({
            'z-index': 100
          });
          popuOverlay.removeClass('open');
          popuOverlaySingle.removeClass('open');
        }

    openBtn.click(openPopup);
    closeBtn.click(closePopup);
    popuOverlay.click(closePopup);

    popuOverlaySingle.click(function(e){
      e.stopPropagation();
    })
  }

  populateSelectedAnswer() {
    let _this = this,
        HtmHolder = $('.selected-answer-text .text'),
        percentHolder = $('.percent-user-answered .percent-selected');

    HtmHolder.text(_this.selectedAnswer);

    percentHolder.text(_this.selectedAnswerPercent.percent + '%');

  }

  animateInElmnts(elm) {
    let _this = this;

    elm.each(function(){
      if (_this.ifElementInView($(this), 50)) {
        $(this).addClass('inView');
      }
    })

    $(window).scroll(function(){
      elm.each(function(){
        if (_this.ifElementInView($(this), 50)) {
          $(this).addClass('inView');
        }
      })
    })
  }

  drawChart(data) {

    let _this = this;

    function drawKioskChart() {
      var pie=d3.layout.pie()
              .value(function(d){return d.percent})
              .sort(null);

      var w=320,h=320;

      var outerRadius=w/2;
      var innerRadius=80;

          var color = d3.scale.ordinal()
        .range(['#6dab3c', '#f5b14b', '#f4524d']);

      var arc=d3.svg.arc()
              .outerRadius(outerRadius)
              .innerRadius(innerRadius);

      var svg=d3.select("#chart")
              .append("svg")
              .attr({
                  width: 700,
                  height: 324, //324, adding 4 because of stroke width
                  class:'donut-chart-svg'
              })
              .style({
                stroke: '#fff',
                'stroke-width': '2'
              })
              .append('g')
              .attr({
                  transform:'translate('+w/2+','+ 324/2 +')' //324, adding 4 because of stroke width
              });
      var path=svg.selectAll('path')
              .data(pie(data))
              .enter()
              .append('path')
              .attr({
                  d:arc,
                  fill:function(d,i){
                      return color(d.data.name);
                  }
              });

      path.transition()
              .duration(2500)
              .attrTween('d', function(d) {
                  var interpolate = d3.interpolate({startAngle: 0, endAngle: 0}, d);
                  return function(t) {
                      return arc(interpolate(t));
                  };
              });


      var restOfTheData=function(){
          var text=svg.selectAll('text')
                  .data(pie(data))
                  .enter()
                  .append("text")
                  .transition()
                  .duration(200)
                  .attr("transform", function (d) {
                      return "translate(" + arc.centroid(d) + ")";
                  })
                  .attr("dy", ".4em")
                  .attr("text-anchor", "middle")
                  .text(function(d){
                      //return d.data.percent+"%";
                  })
                  .style({
                      fill:'#fff',
                      'font-size':'18px'
                  });

          var legendRectSize=20;
          var legendSpacing=7;
          var legendHeight=legendRectSize+legendSpacing;


          var legend=svg.selectAll('.legend')
                  .data(color.domain())
                  .enter()
                  .append('g')
                  .attr({
                      class:'legend',
                      transform:function(d,i){
                          //Just a calculation for x & y position
                          return 'translate(200,' + ((i*40) - 40 ) + ')';
                      }
                  });
        legend.append('text')
                .data(pie(data))
                .attr({
                    x:0,
                    y:15
                })
                .text(function(d){
                    return d.data.percent + '%';
                }).style({
                    fill:'#fff6d9',
                    'font-size':'14px'
                });

          legend.append('rect')
                  .attr({
                      width:legendRectSize,
                      height:legendRectSize,
                      rx: 5,
                      ry: 5,
                      x: 35
                  })
                  .style({
                      fill:color,
                      stroke:color
                  });

          legend.append('text')
                  .data(pie(data))
                  .attr({
                      x:60,
                      y:15
                  })
                  .text(function(d){
                      return d.data.name;
                  }).style({
                      fill:'#fff6d9',
                      'font-size':'14px'
                  });
      };

      restOfTheData();

    }

    function drawWebChart() {

      var colorTheme = $('body').data('color-theme');

      var colorSet;

      var greenColorSet = ['#ffffff', '#80f0a4', '#ccf9dc'],
          blueColorSet = ['#ffffff', '#80c4f0', '#cce9f9'],
          redColorSet = ['#ffffff', '#f08080', '#f9cccc'],
          lavandaColorSet = ['#ffffff', '#c980f0', '#ecccf9'];

      if (colorTheme === 'theme-green') {
        colorSet = greenColorSet;
      } else if (colorTheme === 'theme-blue') {
        colorSet = blueColorSet;
      } else if (colorTheme === 'theme-red') {
        colorSet = redColorSet;
      } else {
        colorSet = lavandaColorSet;
      }

      var pie=d3.layout.pie()
              .value(function(d){return d.percent})
              .sort(null);

      var w=_this.vpWidth,
          h=700,
          outerRadius=160,
          innerRadius=110;

      if (_this.vpWidth <= 640) {
        h=250;
        outerRadius = 53.3333;
        innerRadius = 36.667;
      }

      var color = d3.scale.ordinal()
              .range(colorSet);

      var arc=d3.svg.arc()
              .outerRadius(outerRadius)
              .innerRadius(innerRadius);

      var svg=d3.select("#chart")
              .append("svg")
              .attr({
                  width: w,
                  height: h,
                  class:'donut-chart-svg'
              })
              .append('g')

      if (_this.vpWidth <= 640) {
        svg.attr({
            transform:'translate('+(outerRadius + 20)+','+ (outerRadius + 20) +')'
        });
      } else {
        svg.attr({
            transform:'translate('+w/2+','+ h/2 +')'
        });
      }

      var path=svg.selectAll('path')
              .data(pie(data))
              .enter()
              .append('path')
              .attr({
                  d:arc,
                  fill:function(d,i){
                      return color(d.data.name);
                  }
              });

      path.transition()
              .duration(1000)
              .attrTween('d', function(d) {
                  var interpolate = d3.interpolate({startAngle: 0, endAngle: 0}, d);
                  return function(t) {
                      return arc(interpolate(t));
                  };
              });


      var restOfTheData=function(){
        if (_this.vpWidth <= 640) {
          var legendRectSize=20;
          var legendSpacing=7;
          var legendHeight=legendRectSize+legendSpacing;

          var legend=svg.selectAll('.legend')
                  .data(pie(data))
                  .enter()
                  .append('g')
                  .attr({
                      class:'legend',
                      transform:function(d,i){
                          //Just a calculation for x & y position
                          i = i + 2;
                          return 'translate(-' + (outerRadius) +',' + (i*30) + ')';
                      }
                  });
          legend.append('rect')
                  .data(color.domain())
                  .attr({
                      width:legendRectSize,
                      height:legendRectSize,
                      rx: 5,
                      ry: 5
                  })
                  .style({
                      fill:color,
                      stroke:color
                  });

          legend.append('text')
                  .attr({
                      x:30,
                      y:15
                  })
                  .text(function(d){
                      return d.data.percent + '% ' + d.data.name;
                  }).style({
                      fill:'white',
                      'font-size':'14px'
                  });
        } else {
          var percentsText=svg.selectAll('percentsText')
                  .data(pie(data))
                  .enter()
                  .append("text")
                  .transition()
                  .duration(200)
                  .attr("transform", function(d) {
                    var xPos = arc.centroid(d)[0] * 2;
                    var yPos = arc.centroid(d)[1] * 2;
                    return `translate(${xPos}, ${yPos})`;
                  })
                  .attr("dy", ".4em")
                  .attr("text-anchor", "middle")
                  .attr("class", "label-percents")
                  .text(function(d){
                      return d.data.percent+"%";
                  })

                var labelText=svg.selectAll('labelText')
                  .data(pie(data))
                  .enter()
                  .append("foreignObject")
                  .attr('width', '150')
                  .attr('height', '150')
                  .transition()
                  .duration(200)
                  .attr("transform", function(d) {
                    var xPos = arc.centroid(d)[0] * 2 - (150 / 2);
                    var yPos = arc.centroid(d)[1] * 2 + 32 + 5;
                    return `translate(${xPos}, ${yPos})`;
                  })
                  .attr("dy", ".4em")
                  .attr("text-anchor", "middle")
                  .attr("class", "label-text")
                  .text(function(d){
                      return d.data.name;
                  })
        }
      };

      setTimeout(restOfTheData,1000);
    }

    if (_this.chartType === 'kiosk') {
      drawKioskChart();
    }
    if (_this.chartType === 'web') {
      setTimeout(drawWebChart, 800);
    }
  }
}

export default SingleDilemma;
