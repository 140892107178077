import "babel-polyfill";

import $ from 'jquery';
import sticky from '../vendor/sticky.js';
import slick from '../vendor/slick.js';

var InteractiveMap = class {
  //those prop will be accessible by all methods
  constructor() {
    this.urlHaveParam = window.location.href.indexOf("?") > -1;
    //If want to call all methods when instantiating leave this here, if not every method will needed to be called seperatly after instantiation.

    this.init();

  }

  init() {
    this.mapFilters();
    this.mapNav();
    this.mapPopups();
    this.mobileMaps();
    this.mapRutes();
    this.POIS();
    this.handleEnglishMap();
    if (this.urlHaveParam) {
      this.deepLinkRoutes();
    }
  }

  deepLinkRoutes() {
    let ruteOne = $('.dots-rute-one'),
        ruteTwo = $('.dots-rute-two'),
        ruteThree = $('.dots-rute-three'),
        mapMenu = $('.map-menu'),
        roteOneMobile = $('*[id^="rute-one-mobile"]'),
        roteTwoMobile = $('*[id^="rute-two-mobile"]'),
        mobileMapsNav = $('.slick-dots'),
        colors = {
          'theme-lavanda': '#D885FF',
          'theme-red': '#F83D4E',
          'theme-blue': '#0095FF',
          'theme-green': '#00E14B'
        },
        colorToSet = colors[$('body').data('color-theme')];

        let QueryString = function () {
          // This function is anonymous, is executed immediately and
          // the return value is assigned to QueryString!
          let query_string = {};
          let query = window.location.search.substring(1);
          let vars = query.split("&");
          for (let i=0;i<vars.length;i++) {
            let pair = vars[i].split("=");
                // If first entry with this name
            if (typeof query_string[pair[0]] === "undefined") {
              query_string[pair[0]] = decodeURIComponent(pair[1]);
                // If second entry with this name
            } else if (typeof query_string[pair[0]] === "string") {
              let arr = [ query_string[pair[0]],decodeURIComponent(pair[1]) ];
              query_string[pair[0]] = arr;
                // If third or later entry with this name
            } else {
              query_string[pair[0]].push(decodeURIComponent(pair[1]));
            }
          }
          return query_string;
        }();

        let ruteName = QueryString.rute;

        if ($(window).width() >= 1200) {
          let roofLevelRef = $('#roof'),
              roofLevelRefTop = roofLevelRef.offset().top,
              roofLevelRefHeight = roofLevelRef.get(0).getBBox().height,
              socondLevelRefHeight = $('#second-level').get(0).getBBox().height;

          if (ruteName === 'route-man' || ruteName === 'rute-mennesket') {
            $('html, body').animate({
                scrollTop: roofLevelRefTop + roofLevelRefHeight + socondLevelRefHeight - 100 //dont know why but this 100px center map better
            }, 1000);

          } else {
            //dynamic
            $('html, body').animate({
                scrollTop: roofLevelRefTop + roofLevelRefHeight - 100 //dont know why but this 100px center map better
            }, 1000);
          }
        } else {
          $('.mobile-map').slick('slickGoTo', 1);
          $('html, body').animate({
              scrollTop: $('.mobile-map').offset().top //dont know why but this 100px center map better
          }, 1000);
        }

        if (ruteName === 'route-man' || ruteName === 'rute-mennesket') {
          mapMenu.find('li:nth-child(3)').addClass('active');
          mobileMapsNav.find('li:nth-child(2)').addClass('slick-active');

        } else {
          mapMenu.find('li:nth-child(2)').addClass('active');
          mapMenu.find('li:nth-child(3)').addClass('active');

          mobileMapsNav.find('li:nth-child(2)').addClass('slick-active');
          mobileMapsNav.find('li:nth-child(3)').addClass('slick-active');

        }

        if (ruteName === 'route-water' || ruteName === 'rute-vand') {
          $('#bobleriet >*:first-child, #tappehallens-udstillinger >*:first-child, #stranden >*:first-child, #havnen >*:first-child')
            .attr({
              fill: colorToSet
            })
          ruteOne.delay( 800 ).fadeIn();
        }

        if (ruteName === 'route-phenomena' || ruteName === 'rute-faenomener') {
          $('#bobleriet >*:first-child, #cirkus-fysikus >*:first-child, #lyslabyrinten >*:first-child, #tappehallens-udstillinger >*:first-child')
            .attr({
              fill: colorToSet
            })
          ruteTwo.delay( 800 ).fadeIn();
        }

        if (ruteName === 'route-man' || ruteName === 'rute-mennesket') {
          $('#puls-torvet >*:first-child, #under-huden >*:first-child, #sansetunnellen >*:first-child')
            .attr({
              fill: colorToSet
            })
          ruteThree.delay( 800 ).fadeIn();
        }
  }

  handleEnglishMap() {
    let isEnglish = $('.interactive-map-content').data('english');

    $('*[id^="eng-text"]').hide();

    if (isEnglish) {
      $('*[id^="eng-text"]').show();
      $('*[id^="txt-"]').hide();
    }
  }

  mapRutes() {
    let ruteOne = $('.dots-rute-one'),
        ruteTwo = $('.dots-rute-two'),
        ruteThree = $('.dots-rute-three'),
        mapMenu = $('.map-menu'),
        roteOneMobile = $('*[id^="rute-one-mobile"]'),
        roteTwoMobile = $('*[id^="rute-two-mobile"]'),
        mobileMapsNav = $('.slick-dots');

    ruteOne.hide();
    ruteTwo.hide();
    ruteThree.hide();

    let isEnglish = $('.interactive-map-content').data('english');

    $('.dropdown-select_rute li').click(function(){
      let ruteName = isEnglish ? $(this).data('rute-name-eng') : $(this).data('rute-name-den');


      if (!isEnglish) {
        history.pushState({}, 'rute url', '?rute=' + ruteName);

      } else {
        history.pushState({}, 'rute url', '?rute=' + ruteName);
      }

      let ruteToShow = $(this).data('rute'),
            colors = {
              'theme-lavanda': '#D885FF',
              'theme-red': '#F83D4E',
              'theme-blue': '#0095FF',
              'theme-green': '#00E14B'
            },
            colorToSet = colors[$('body').data('color-theme')];


      if ($(this).data('rute') !== '.dots-rute-three') {
        mapMenu.find('li').removeClass('active');
        mapMenu.find('li:nth-child(2)').addClass('active');
        mapMenu.find('li:nth-child(3)').addClass('active');

        mobileMapsNav.find('li').removeClass('slick-active');
        mobileMapsNav.find('li:nth-child(2)').addClass('slick-active');
        mobileMapsNav.find('li:nth-child(3)').addClass('slick-active');
      } else {
        mapMenu.find('li').removeClass('active');
        mapMenu.find('li:nth-child(3)').addClass('active');

        mobileMapsNav.find('li').removeClass('slick-active');
        mobileMapsNav.find('li:nth-child(2)').addClass('slick-active');
      }


      $('#first-level').find('#for-coloring > g >*:first-child').attr({
        fill: '#CBCBCD'
      })

      $('#second-level').find('#for-coloring > g >*:first-child').attr({
        fill: '#0F1627'
      })

      $('#level-1').find('#exhibitions > g >*:first-child').attr({
        fill: '#CBCBCD'
      })

      $('#level-2').find('#exhibitions > g >*:first-child').attr({
        fill: '#0F1627'
      })

      ruteOne.hide();
      ruteTwo.hide();
      ruteThree.hide();

      $(ruteToShow).delay( 800 ).fadeIn();

      $('#entry').attr({
        fill: colorToSet
      })

      if (ruteToShow === '.dots-rute-one') {
        $('#bobleriet >*:first-child, #tappehallens-udstillinger >*:first-child, #stranden >*:first-child, #havnen >*:first-child')
          .attr({
            fill: colorToSet
          })
      } else if (ruteToShow === '.dots-rute-two') {
        $('#bobleriet >*:first-child, #cirkus-fysikus >*:first-child, #lyslabyrinten >*:first-child, #tappehallens-udstillinger >*:first-child')
          .attr({
            fill: colorToSet
          })
      } else {
        $('#puls-torvet >*:first-child, #under-huden >*:first-child, #sansetunnellen >*:first-child')
          .attr({
            fill: colorToSet
          })
      }
    })
  }

  mapFilters() {
    let ageFilterRef = $('.age-filter'),
        tagFilterRef = $('.tag-filter'),
        exhibitionJsonData = JSON.parse(exhibitionsJSON),
        ageArray = getArrayFromData(exhibitionJsonData, 'age'),
        tagArray = getArrayFromData(exhibitionJsonData, 'tag'),
        tagArrayAll = exhibitionJsonData.dropdown_tags,
        dropDownTooglerRef = $('.dropdown-toogler'),
        dropDownAll = dropDownTooglerRef.next(),
        dropDownRef,
        _this = this;

    let ruteOne = $('.dots-rute-one'),
        ruteTwo = $('.dots-rute-two'),
        ruteThree = $('.dots-rute-three');

    function getArrayFromData(obj, objProp) {
      let ageArray = [],
          indexOfFalseValue;
      //get array
      for (let prop in obj) {
        ageArray.push(obj[prop][objProp])
      }
      //make array of unique items
      ageArray = [ ...new Set(ageArray) ];

      //remove falses from array if any
      ageArray = ageArray.filter(function(item){
        return item !== undefined;
      })

      // sort items
      ageArray.sort(function (a, b){
        a = a.split(' ');
        b = b.split(' ');
        if (a[0] == b[0]) {
          return parseInt(a[1]) - parseInt(b[1]);
        }
        return a[0] > b[0];
        })

      return ageArray;
    }

    function populateDropDown(dropDown, data) {
      for (let i = 0; i < data.length; i++) {
        dropDown.append(`<li>${data[i]}</li>`);
      }
    }

    function returnFilteredItems(obj, filterValue, filter) {
      let filteredItems = new Array();

      for (let prop in obj) {
        if (obj[prop][filter] === filterValue) {
          filteredItems.push(prop);
        }
      }
      return filteredItems;
    }


    function returnFilteredItemsByString(obj, filterValue, filter) {
        let filteredItems = new Array();

      for (let prop in obj) {
        if (obj[prop]['tag'] != undefined) {
          if (obj[prop]['tag'].indexOf(filterValue) !== -1) {
            filteredItems.push(prop);
          }
        }

      }
      return filteredItems;
    }

    function colorFilteredExb(filteredExb) {

      $('#first-level').find('#for-coloring > g >*:first-child').attr({
        fill: '#CBCBCD'
      })

      $('#second-level').find('#for-coloring > g >*:first-child').attr({
        fill: '#0F1627'
      })

      $('#level-1').find('#exhibitions > g >*:first-child').attr({
        fill: '#CBCBCD'
      })

      $('#level-2').find('#exhibitions > g >*:first-child').attr({
        fill: '#0F1627'
      })

      for (let i = 0; i < filteredExb.length; i++) {
        let forColoringCurrent = $('#' + filteredExb[i] + '>*:first-child'),
            colors = {
              'theme-lavanda': '#D885FF',
              'theme-red': '#F83D4E',
              'theme-blue': '#0095FF',
              'theme-green': '#00E14B'
            },
            colorToSet = colors[$('body').data('color-theme')];

        let beforeFillColor = forColoringCurrent.attr('fill');

        forColoringCurrent.attr('fill', colorToSet);
      }
    }


    populateDropDown(ageFilterRef, ageArray);
    populateDropDown(tagFilterRef, tagArrayAll);

    dropDownRef = $('.dropdown-select li');

    dropDownTooglerRef.click(function(){
      let dropDownCurrent = $(this).next();

      if (!$(this).hasClass('toggled')) {
        dropDownAll.slideUp();
        dropDownTooglerRef.removeClass('toggled');
        $(this).addClass('toggled');
        dropDownCurrent.slideDown();
      } else {
        $(this).removeClass('toggled');
        dropDownCurrent.slideUp();
      }
    })

    dropDownRef.click(function(){
      let currentFilterValue = $(this).text(),
          currentFilter = $(this).parent().data('filter'),
          currentDropDownTxtRef = $(this).parent().parent().find('.dropdown-item-txt'),
          filteredExb = returnFilteredItems(exhibitionJsonData, currentFilterValue, currentFilter);

      if (currentFilter == 'tag') {
        filteredExb = returnFilteredItemsByString(exhibitionJsonData, currentFilterValue, currentFilter);
      } else {
        filteredExb = returnFilteredItems(exhibitionJsonData, currentFilterValue, currentFilter);
      }

      $('.map-menu').find('li').removeClass('active');
      $('.mobile-map').slick('slickGoTo', 1);
      ruteOne.hide();
      ruteTwo.hide();
      ruteThree.hide();

      $('#entry').attr({
        fill: '#E7E6E7'
      })

      $('.interactive-map-content').addClass('filter-clicked');

      dropDownTooglerRef.removeClass('toggled');
      dropDownAll.slideUp();

      if (currentFilterValue.length > 15) {
        currentFilterValue = currentFilterValue.substring(0,15) + '...';
      }

      currentDropDownTxtRef.text(currentFilterValue);

      if (!filteredExb.length < 1) {
        colorFilteredExb(filteredExb);
      } else {

      }

      if ($(window).width() >= 1200) {
        let roofLevelRef = $('#roof'),
            roofLevelRefTop = roofLevelRef.offset().top,
            roofLevelRefHeight = roofLevelRef.get(0).getBBox().height,
            socondLevelRefHeight = $('#second-level').get(0).getBBox().height;

        if ($(this).data('rute') === '.dots-rute-three') {
          $('html, body').animate({
              scrollTop: roofLevelRefTop + roofLevelRefHeight + socondLevelRefHeight - 100 //dont know why but this 100px center map better
          }, 1000);
        } else {
          //dynamic
          $('html, body').animate({
              scrollTop: roofLevelRefTop + roofLevelRefHeight - 100 //dont know why but this 100px center map better
          }, 1000);

          //static
          // $('html, body').animate({
          //     scrollTop: 730
          // }, 800);
        }
      } else {
        $('html, body').animate({
            scrollTop: $('.mobile-map').offset().top //dont know why but this 100px center map better
        }, 1000);
      }

    })

  }

  POIS() {
    let poiRef = $('g[id^="icons-"] > g, #poi > g, #dots-poi > circle, #dots-poi-mobile > ellipse'),
        infoBalloon = $('.info-balloon'),
        infoBalloonTxtRef = $('.info-balloon p'),
        infoBalloonTitleRef = $('.info-balloon p.info-balloon-title'),
        closeBalloonBtn = $('.close-ballon'),
        infoBalloonPOS,
        ifNotInViewOffsetLeft = 0,
        ifNotInViewOffseTop = 0,
        colors = {
          'theme-lavanda': '#D885FF',
          'theme-red': '#F83D4E',
          'theme-blue': '#0095FF',
          'theme-green': '#00E14B'
        };

        $('#dots-poi-mobile > ellipse').click(function(){
          $('#dots-poi-mobile > ellipse').removeClass('colored-dots');
          $(this).addClass('colored-dots');
        });

        $('#dots-poi > circle').click(function(){
          $('#dots-poi > circle').removeClass('colored-dots');
          $(this).addClass('colored-dots');
        });

        poiRef.on('click', function(){
          infoBalloonPOS = $(this)[0].getBoundingClientRect();
          let poiTxt = JSON.parse(exhibitionsPOIS)[$(this).attr('id')];
          let infoBalloonHeight = infoBalloon.outerHeight();
          let infoBalloonWidth = infoBalloon.outerWidth();
          let leftInView = $(window).width() > (infoBalloonPOS.left +  infoBalloonWidth + 40); //40 width of poi icon
          let topInView = $(window).height() > ($(this)[0].getBoundingClientRect().top +  infoBalloonHeight);

          if (!leftInView) {
            ifNotInViewOffsetLeft = infoBalloonWidth;
          } else {
            ifNotInViewOffsetLeft = - 40; //40 width of poi icon
          }

          if (!topInView) {
            ifNotInViewOffseTop = infoBalloonHeight;
          } else {
            ifNotInViewOffseTop = 0;
          }

          infoBalloonTxtRef.text(poiTxt.text);
          infoBalloonTitleRef.text(poiTxt.title);

          infoBalloon.css({
            top: (infoBalloonPOS.top + $(window).scrollTop()) - ifNotInViewOffseTop,
            left: infoBalloonPOS.left - ifNotInViewOffsetLeft
          })

          infoBalloon.fadeIn();

        })

        closeBalloonBtn.on('click', function(){
          infoBalloon.fadeOut();
          $('#dots-poi-mobile > ellipse').removeClass('colored-dots');
          $('#dots-poi > circle').removeClass('colored-dots');

        })
  }

  mobileMaps() {
    let infoBalloon = $('.info-balloon');
    $('.mobile-map').on('beforeChange', function(event, slick, direction){
      infoBalloon.fadeOut();
    });

    $('.mobile-map').slick({
      dots: true,
      arrows: false,
      initialSlide: 1,
      customPaging: function(slider, i) {
        var levelName = $(slider.$slides[i]).data('name');
        return levelName;
      }
    });
  }

  mapPopups() {
    let btn = $('#for-coloring > g, svg #exhibitions > g'),
        exhibitionJsonData = JSON.parse(exhibitionsJSON), //ths json can be found on map-interactive.php
        exhibitionPopupRef = $('.exhibitions-popup'),
        closeExhibitionPopup = $('.close-exhibitions-popup'),
        titleRef = $('.popup-title'),
        introTxtRef = $('.popup-txt'),
        imgRef = $('.popup-img'),
        linkRef = $('.popup-link'),
        ageRef = $('.popup-age'),
        timeRef = $('.popup-time'),
        tagRef = $('.popup-tag'),
        btnref = $('.popup-link');


    //Open and populate popup
    btn.on('click', function(){
      let isEnglish = $('.interactive-map-content').data('english');


      let exhibitionId = $(this).attr('id'),
          exhibitionName = isEnglish ? $(this).data('popup-eng') : $(this).data('popup-den'),
          exhibitionData = exhibitionJsonData[exhibitionId],
          _this = $(this),
          colors = {
            'theme-lavanda': '#D885FF',
            'theme-red': '#F83D4E',
            'theme-blue': '#0095FF',
            'theme-green': '#00E14B'
          };

          // console.log(exhibitionId);

          if ($('.interactive-map-content').hasClass('filter-clicked')) {
            colors = {
              'theme-lavanda': 'rgba(216, 133, 255, .5)',
              'theme-red': 'rgba(248, 61, 78, .5)',
              'theme-blue': 'rgba(0, 149, 255, .5)',
              'theme-green': 'rgba(0, 225, 75, .5)'
            };
          }


          let populateExhibitionPopup = function(){
            titleRef.text(exhibitionData.name);
            introTxtRef.text(exhibitionData.introTxt.replace(/&quot;/g, '"'));
            imgRef.attr('src', exhibitionData.image);
            linkRef.attr('href', exhibitionData.url);
            ageRef.text(exhibitionData.age);
            timeRef.text(exhibitionData.time);
            tagRef.text(exhibitionData.tag);
            btnref.text(exhibitionData.buttonTxt);
          }

          let openExhibitionPopup = function(callback) {
            callback();
            if ($(window).width() >= 1200) {
              exhibitionPopupRef.fadeIn();
            } else {
              let currentColorTouch = _this.children().first().attr('fill');

              exhibitionPopupRef.delay(200).fadeIn(function(){
                _this.children().first().attr({
                  fill: currentColorTouch
                })
              });
              _this.children().first().attr({
                fill: colors[$('body').data('color-theme')]
              })
            }
          }

          openExhibitionPopup(populateExhibitionPopup);


    });


    closeExhibitionPopup.click(function(){
      $.when(exhibitionPopupRef.fadeOut()).done(function() {
        imgRef.attr('src', '');
      });
    })

    exhibitionPopupRef.click(function(){
      $.when(exhibitionPopupRef.fadeOut()).done(function() {
        imgRef.attr('src', '');
      });
    })

    $('.omit-click').click(function(e){
      e.stopPropagation();
    })
  }

  mapNav() {

    let menu = $('.map-menu'),
        menuItems = menu.find('li a'),
        svgMapRef = $('#interactive-map-svg'),
        mapWrapperRef = $('.interactive-map-content'),
        openMapBtn = $('.open-map-btn'),
        iconsFroBounce = $('#for-coloring > g > g');

    let svgElements = {
      roof: $('#roof'),
      secondLevel: $('#second-level'),
      firstLevel: $('#first-level'),
      groundLevel: $('#ground-level'),
      conectors: {
        second: $('#second-level-conectors'),
        first: $('#first-level-conectors'),
        ground: $('#ground-level-conectors')
      }
    }

    $( window ).scroll(() => {
      let elemAfterMapTopOffset = $('.interactive-map-content').next()[0].getBoundingClientRect().top;

      if (elemAfterMapTopOffset <= 415) {
        menu.fadeOut();
      } else {
        menu.fadeIn();
      }

    })

    //Sliding to selected map floor
    menuItems.click(function(e) {
      let offset = -300; //Offset of 20px
      let elmToScrollTo = $(this).attr('href');

      menuItems.parent().removeClass('active');
      $(this).parent().addClass('active');

      e.preventDefault();

      $('html, body').animate({
          scrollTop: $(elmToScrollTo).offset().top + offset
      }, 300);
    });

    function animateIcons() {

      if ($('body').hasClass('ie11') || $('body').hasClass('browser-edge')) {
        return;
      } else {
        //Bounce animation on icons
        iconsFroBounce.each(function(){

          let currentIconToBounce = $(this),
              initalTransform = currentIconToBounce.attr('transform'),
              splitedStringToArray = initalTransform.split(','),
              initalTransformForAddingString = splitedStringToArray[0],
              initalTransformForAddingNumber = parseInt(splitedStringToArray[1]),
              dynamicTransform = initalTransformForAddingString + ', ' + (initalTransformForAddingNumber - 21) + ')',
              dynamicTransformWithPX = initalTransformForAddingString + 'px, ' +(initalTransformForAddingNumber - 21) + 'px)',
              initalTransformWithPX = initalTransformForAddingString + 'px, ' + initalTransformForAddingNumber + 'px)';

          currentIconToBounce.attr({
            'transform': dynamicTransform
          })

          let boounceIconTween = TweenLite.to(currentIconToBounce, 2, {
            'transform': initalTransformWithPX,
            delay: 0.7,
            ease:Bounce.easeOut
          });
        })


        $('#dots-poi > circle').addClass('drop-that-dots');
      }
    }

    function explodeMap() {
      mapWrapperRef.addClass('exploded');
      //width="1206px" height="521px" viewBox="0 0 1206 521"
      if (svgMapRef.length !== 0) {
        svgMapRef[0].setAttribute('viewBox', '0 0 1206 1577');
        svgMapRef[0].setAttribute('height', '1577px');
      }

      $('.tint-map-wrapper').fadeOut();

      //stick map nav
      menu.sticky({topSpacing:160});

    }

    if ($('body').hasClass('ie11')) {
      condestMap();
    }

    function condestMap() {
      svgElements.secondLevel.attr({
        transform: 'translate(0, 0)'
      })

      svgElements.firstLevel.attr({
        transform: 'translate(1, 76)'
      })

      svgElements.groundLevel.attr({
        transform: 'translate(1, 159)'
      })

      svgElements.conectors.second.attr({
        transform: 'translate(0, 21) scale(0, 0)'
      })

      svgElements.conectors.first.attr({
        transform: 'translate(0, 22) scale(0, 0)'
      })

      svgElements.conectors.ground.attr({
        transform: 'translate(582, 175) scale(0, 0)'
      })
    }

    function explodeMapIE() {

      svgElements.secondLevel.attr({
        transform: 'translate(0, 340)'
      })

      svgElements.firstLevel.attr({
        transform: 'translate(1, 774)'
      })

      svgElements.groundLevel.attr({
        transform: 'translate(1, 1236)'
      })

      svgElements.conectors.second.attr({
        transform: 'translate(0, 21) scale(1, 1)'
      })

      svgElements.conectors.first.attr({
        transform: 'translate(0, 361) scale(1, 1)'
      })

      svgElements.conectors.ground.attr({
        transform: 'translate(582, 872) scale(1, 1)'
      })
    }


    openMapBtn.click(() => {
      if ($('body').hasClass('ie11')) {
        explodeMapIE();
      }

      explodeMap();
      animateIcons();

      $('.interactive-map-intro-filters').addClass('map-exploded');

    })
    //opening map after chosing filters
    $('.dropdown-select li').click((event) => {
      if (!$('.interactive-map-intro-filters').hasClass('map-exploded')) {
        if ($('body').hasClass('ie11')) {
          explodeMapIE();
        }

        explodeMap();
        animateIcons();
      }
      $('.interactive-map-intro-filters').addClass('map-exploded');
    })

    if (this.urlHaveParam) {
      if ($('body').hasClass('ie11')) {
        explodeMapIE();
      }

      explodeMap();
    }

  }

}
export default InteractiveMap;
