'use strict'

import GCCarousel from '../vendor/GridColumnCarousel.js';

export default (elem) => {
  //get options for the carousel plugin
  var options = {
    elem: elem,
    gridColClasses: 'carousel__page',
    autoplay: true
  };

  var gCCarousel = new GCCarousel(options);
};
