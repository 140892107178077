'use strict'

import $ from 'jquery';

var randomTeaser = class {
  constructor(teasers, flipClasses) {
    this.teasers = teasers;
    this.enableTeaserFlip = $('main').data('random-teaser');
    this.flipClasses = flipClasses;

    //setted throught metods
    this.curentTrickyClass = undefined;

    this.helper = {
      getRandomNumberInBetwen: function (fromNumber, toNumber) {
        return Math.floor(Math.random() * (toNumber - fromNumber + 1)) + fromNumber;
      },
      isTouchDevice: function () {
        return 'ontouchstart' in window || navigator.maxTouchPoints;
      }
    }

    if (this.enableTeaserFlip && !this.helper.isTouchDevice()) {
      this.init();
    }
  }

  init() {
    var me = this;

    me.flipTeaser(me.getRandomTeaser());
    me.normalizeTeaser();

    me.getRandomFlipClass();

  }

  getRandomFlipClass() {
    var me = this;

    var classesSum = me.flipClasses.length,
        randomNumber = me.helper.getRandomNumberInBetwen(0, classesSum - 1);

    return me.flipClasses[randomNumber];
  }

  getRandomTeaser() {
    var me = this;


    var teaserSum = me.teasers.length,
        randomNumber = me.helper.getRandomNumberInBetwen(0, teaserSum - 1);

    return me.teasers.eq(randomNumber);
  }

  flipTeaser(teaser) {
    var me = this;

    var trickyClass = me.getRandomFlipClass();

    me.curentTrickyClass = trickyClass;

    if (me.enableTeaserFlip) {
      teaser.addClass(trickyClass);
    }
  }

  normalizeTeaser() {
    var me = this;

    me.teasers.mouseleave(function(){
      $(this).removeClass(me.curentTrickyClass);
    })
  }
}

export default randomTeaser;
