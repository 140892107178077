'use strict';

var Helper = class {
  constructor() {

    /**
    * A method that check what device are user on
    * @param {String} device - possible options: mobile, tablet and desktop
    * @param {Intriger} breakPoint - break point for chosen device, if not entered default value will be setup
    * @return {Boolean} - true/false for chosen options
    */
    this.isTouch = function() {
      return 'ontouchstart' in window || navigator.maxTouchPoints;
    }

  };
};

export default Helper;
