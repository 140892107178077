'use strict'

import Isotope from 'isotope-layout';
import ShowMoreList from './showMore';
import $ from 'jquery';
import 'jquery.waitforimages';
import Webshop from './webshop';

export default (elem) => {
  var self = this;
  var iso;
  var layoutMode = 'masonry';

  var webshop = new Webshop();

  //initialize the Isotop layout
  var initialize = (result) => {

    var receivedData = result;
    //var receivedData = JSON.parse(result);
    var scheduleReceivedArray = [];
    var shopReceivedArray = [];
    var receivedArray = receivedData.responseData.entries;
    _.each(receivedArray, function (e) {
        if(e.hasOwnProperty('schedules')){
          scheduleReceivedArray.push(e);
        }
        else {
          shopReceivedArray.push(e);
        }
    });
    var randomShopElement;
    if($(document).find('main').hasClass('home')){
      randomShopElement = _.where(shopReceivedArray, {"show_on_frontpage": true});
      randomShopElement = _.sample(randomShopElement);
    }
    else {
      randomShopElement = _.sample(shopReceivedArray);
    }

    if (!randomShopElement.tags === null) {
      var splitedTags = randomShopElement.tags.split(',');
      splitedTags.splice(3);
      splitedTags.push('...');
      var splitedTagsString = splitedTags.join(', ');
    }

    $(document).find('.isoTope .top-5').after(
    '<div class="columns small-12 medium-6 large-3 web-shop-item-in-isotope show-me-with-isotope">' +
      '<div class="thumbnail-default--event">' +
        '<a data-text="Se og køb" href="' + randomShopElement.url + '" target="_blank"> ' +
          '<div class="thumbnail__image">' +
            '<img class="random-image-isotope-webshop" src="' + randomShopElement.image_path + '">' +
          '</div>' +
          '<div class="thumbnail__content">' +
            '<h3 class="header--smaller">' + randomShopElement.name + '</h3>' +
            '<p class="description">' + randomShopElement.contentSnippet + '</p>' +
            '<div class="tags tags--neutral">' +
              '<div class="clearfix"></div>' +
              '<div data-text="Se og køb" class="label-btn float-left">' +
                '<span class="label-btn__text">Se og køb</span> ' +
                '<span class="label-btn__icon"> ' +
                  '<svg class="svg-icon"> ' +
                    '<use xlink:href="/wp-content/themes/experimentarium/assets/images/assets/SVGSpritesheet.svg#cart"></use> ' +
                  '</svg> ' +
                '</span> ' +
                '<span class="webshop-price label-btn__label">' + randomShopElement.price + ' DKK</span> ' +
              '</div> ' +
              '<div class="clearfix"></div>' +
            '</div>' +
          '</div>' +
        '</a>' +
      '</div>' +
    '</div>'
    );



    iso = new Isotope(elem, {
      itemSelector: '.columns',
      filter: '.show-me-with-isotope',
      masonry: {
        // use outer width of grid-sizer for columnWidth
        columnWidth: '.grid-sizer'
      },
      layoutMode: layoutMode
    });


    var elementsToHide = document.getElementsByClassName('hide-me-with-isotope');
    var showMoreBtn = $('.show-more');

    showMoreBtn.click(function(){
      iso.arrange({
        filter: '.show-me-with-isotope, .hide-me-with-isotope'
      })
    })

    $('.web-shop-item-in-isotope').waitForImages(function(){
      iso.arrange({
        filter: '.show-me-with-isotope'
      })
    })

    //if there it no items that are hidden ==> hide show more btn, note, this need to work on smoothstate also

    let hiddenElementsPresent = $('.isoTope').find('.hide-me-with-isotope').length;

    if (!hiddenElementsPresent) {
      showMoreBtn.hide();
    }

  };

  webshop.getData(initialize);

  //use this until webshop is live again, then when it's live use line above with webshio.getData!
  //when all images have loaded, initialize.
    //$(elem).waitForImages(initialize);

};
