'use strict';

import $ from 'jquery';
import Pubsub from 'pubsub-js';
import debounce from 'lodash/debounce';

var buyCopy = 'Se og køb';
if(shoplang == 'en_US') {
  buyCopy = 'See and buy';
}

var Webshop = class {
  constructor() {

  }

  initAll() {
    this.getData(this.isotopeAppend);
    this.getData(this.latestArticleAppend);
    this.getData(this.webShopAppend);
    this.getData(this.calendarAppend);
  }

  getData(callback) {
    var me = this;

    //var url = 'http://127.0.0.1:8080/shop_sample.json'; //Use for testing, because of CORS
    var url = "https://shop.experimentarium.dk/wpi/feed/index/store/danish";
    if(shoplang == 'en_US'){
      url = "https://shop.experimentarium.dk/wpi/feed/index/store/english";
    }

    $.ajax({
      method: "GET",
      //url: "https://shop.experimentarium.dk/wpi/feed",
      url: url
      //url: "/static/webshop.json"
    }).done(callback);
  }

  isotopeAppend(result) {
    var receivedData = result;
    //var receivedData = JSON.parse(result);
    var scheduleReceivedArray = [];
    var shopReceivedArray = [];
    var receivedArray = receivedData.responseData.entries;
    _.each(receivedArray, function (e) {
        if(e.hasOwnProperty('schedules')){
          scheduleReceivedArray.push(e);
        }
        else {
          shopReceivedArray.push(e);
        }
    });
    var randomShopElement;
    if($(document).find('main').hasClass('home')){
      randomShopElement = _.where(shopReceivedArray, {"show_on_frontpage": true});
      randomShopElement = _.sample(randomShopElement);
    }
    else {
      randomShopElement = _.sample(shopReceivedArray);
    }

    $(document).find('.isoTope .grid-sizer').after(
    '<div class="columns small-12 medium-6 large-3 web-shop-item-in-isotope show-me-with-isotope bugy-hover-fix">' +
      '<div class="thumbnail-default--event">' +
        '<a data-text="Se og køb" href="' + randomShopElement.url + '" target="_blank"> ' +
          '<div class="thumbnail__image">' +
            '<img src="' + randomShopElement.image_path + '">' +
          '</div>' +
          '<div class="thumbnail__content">' +
            '<h3 class="header--smaller">' + randomShopElement.name + '</h3>' +
            '<p class="description">' + randomShopElement.contentSnippet + '</p>' +
            '<div class="tags tags--neutral">' +
              '<svg class="svg-icon icon--tag">' +
                '<use xlink:href="/wp-content/themes/experimentarium/assets/images/assets/SVGSpritesheet.svg#tags"></use>' +
              '</svg>' +
              '<div class="tag-group">' +
                '<span class="tag">' + randomShopElement.tags + '</span>' +
              '</div>' +
              '<div class="clearfix"></div>' +
              '<div data-text="Se og køb" class="label-btn float-left">' +
                '<span class="label-btn__text">' + buyCopy + '</span> ' +
                '<span class="label-btn__icon"> ' +
                  '<svg class="svg-icon"> ' +
                    '<use xlink:href="/wp-content/themes/experimentarium/assets/images/assets/SVGSpritesheet.svg#cart"></use> ' +
                  '</svg> ' +
                '</span> ' +
                '<span class="webshop-price label-btn__label">' + randomShopElement.price + ' DKK</span> ' +
              '</div> ' +
              '<div class="clearfix"></div>' +
            '</div>' +
          '</div>' +
        '</a>' +
      '</div>' +
    '</div>'
    );


  }

  latestArticleAppend(result) {
    var receivedData = result;
    //var receivedData = JSON.parse(result);
    var scheduleReceivedArray = [];
    var shopReceivedArray = [];
    var shopReceivedArrayTags = [];
    var finalArray;
    var receivedArray = receivedData.responseData.entries;
    var postTagsElements = $(document).find('.latest-article-wrapper.has-webshop-item').data('tags');

    if (postTags != undefined) {
      var postTags = postTagsElements.split(',');
      _.each(receivedArray, function (e) {
          
          if(e.hasOwnProperty('schedules')){
            scheduleReceivedArray.push(e);
          } else {
            
            //Check for tags
            var elementTags = e.tags.split(',');

            _.each(elementTags, function (c) {
              if (_.contains(postTags, c)) {
                shopReceivedArrayTags.push(e);
              } else {
                shopReceivedArray.push(e);
              }

            });
          }
      });
    } else {
      finalArray = receivedArray;
    }

    if (shopReceivedArrayTags.length > 0) {
      finalArray = shopReceivedArrayTags;
    } else {
      finalArray = receivedArray;
    }

    if (finalArray.length > 0) {

      var randomShopElement;
      if($(document).find('main').hasClass('home')){
        randomShopElement = _.where(finalArray, {"show_on_frontpage": true});
        if (randomShopElement != undefined) {
          randomShopElement = _.sample(randomShopElement);
        }
      } else {
        randomShopElement = _.sample(finalArray);
      }

      if (!randomShopElement.tags === null) {
        var splitedTags = randomShopElement.tags.split(',');
        splitedTags.splice(3);
        splitedTags.push('...');
        var splitedTagsString = splitedTags.join(', ');
      }

      $(document).find('.latest-article-wrapper.has-webshop-item').append(

        '<div class="small-12 medium-6 large-4 columns scroll-animate scroll-animate--fade-up scroll-animate--delay-2">' +
            '<div class="thumbnail-default--product"><a href="' + randomShopElement.url + '">' +
                '<div class="thumbnail__image"><img src="' + randomShopElement.image_path + '"></div>' +
                '<div class="thumbnail__content">' +
                  '<h3 class="header--smaller">' + randomShopElement.name + '</h3>' +
                  '<p class="description">' + randomShopElement.contentSnippet + '</p>' +
                  '<button data-text="' + buyCopy + '" class="label-btn"><span class="label-btn__text">' + buyCopy + '</span><span class="label-btn__icon">' +
                      '<svg class="svg-icon">' +
                        '<use xlink:href="/wp-content/themes/experimentarium/assets/images/assets/SVGSpritesheet.svg#cart"></use> ' +
                      '</svg></span><span class="label-btn__label">' + randomShopElement.price + ' DKK</span></button>' +
                '</div></a></div>' +
          '</div>'
      );
    }
  }

  webShopAppend(result) {
    var receivedData = result;
    //var receivedData = JSON.parse(result);
    var scheduleReceivedArray = [];
    var shopReceivedArray = [];
    var receivedArray = receivedData.responseData.entries;
    _.each(receivedArray, function (e) {
        if(e.hasOwnProperty('schedules')){
          scheduleReceivedArray.push(e);
        }
        else {
          shopReceivedArray.push(e);
        }
    });

    var randomShopElement;
    if($(document).find('main').hasClass('home')){
      randomShopElement = _.where(shopReceivedArray, {"show_on_frontpage": true});

      if (randomShopElement != undefined) {
        randomShopElement = _.sample(randomShopElement);
      }
    } else {
      randomShopElement = _.sample(receivedArray);
    }

    if (!randomShopElement.tags === null) {
      var splitedTags = randomShopElement.tags.split(',');
      splitedTags.splice(3);
      splitedTags.push('...');
      var splitedTagsString = splitedTags.join(', ');
    }

    $(document).find('.webshop-shop').append(
    '<img src="'+ randomShopElement.banner_image_path +'" class="product-background-image" />' +
    '<div class="row align-center web-shop-item-in-home"> ' +
      '<div class="call-to-action__image columns small-12 medium-10 large-6"> ' +
        '<img src="' + randomShopElement.image_path + '" class="webshop-img scroll-animate scroll-animate--fade-up"> ' +
      '</div>' +
      '<div class="call-to-action__content columns small-12 medium-10 large-6"> ' +
        '<a href="' + randomShopElement.url + '" target="_blank"> ' +
          '<h3 class="webshop-header header--medium">' + randomShopElement.name + '</h3> ' +
        '</a> ' +
        '<p class="webshop-text">' + randomShopElement.contentSnippet + '</p>' +
        '<a data-text="' + buyCopy + '" class="label-btn label-btn--invert btn--invert float-left" href="' + randomShopElement.url + '" target="_blank"> ' +
          '<span class="label-btn__text">' + buyCopy + '</span> ' +
          '<span class="label-btn__icon"> ' +
            '<svg class="svg-icon"> ' +
              '<use xlink:href="/wp-content/themes/experimentarium/assets/images/assets/SVGSpritesheet.svg#cart"></use> ' +
            '</svg> ' +
          '</span> ' +
        '<span class="webshop-price label-btn__label">' + randomShopElement.price + ' DKK</span> ' +
        '</a> ' +
      '</div>' +
    '</div>'
    );
  }

  calendarAppend(result) {
    var receivedData = result;
    //var receivedData = JSON.parse(result);
    var scheduleReceivedArray = [];
    var shopReceivedArray = [];
    var receivedArray = receivedData.responseData.entries;
    _.each(receivedArray, function (e) {
        if(e.hasOwnProperty('schedules')){
          scheduleReceivedArray.push(e);
        }
        else {
          shopReceivedArray.push(e);
        }
    });
    var randomShopElement;
    if($(document).find('main').hasClass('home')){
      randomShopElement = _.where(shopReceivedArray, {"show_on_frontpage": true});
      randomShopElement = _.sample(randomShopElement);
    }
    else {
      randomShopElement = _.sample(shopReceivedArray);
    }

    _.each(scheduleReceivedArray, function (e) {
      var firstDate = _.first(e.schedules);
      var lastDate = _.last(e.schedules);
      firstDate = firstDate.date;
      lastDate = lastDate.date;
      $(document).find('.webshop-container.webshop-calendar').append(
        '<div class="small-12 medium-10 large-6 columns scroll-animate scroll-animate--fade-up web-shop-item-in-home"> ' +
          '<div class="thumbnail-event"> ' +
            '<a href="' + e.url + '"> ' +
              '<div class="row collapse"> ' +
                '<div class="thumbnail__image small-12 medium-5 columns"> ' +
                  '<img src="' + e.image_path + '"> ' +
                '</div> ' +
                '<div class="thumbnail__content small-12 medium-7 columns"> ' +
                  '<h3 class="header--smaller">' + e.name + '</h3> ' +
                  '<p class="text--small meta dates">' + firstDate + ' - ' + lastDate + '</p> ' +
                  '<p class="description">' + e.contentSnippet + '</p> ' +
                '</div> ' +
              '</div> ' +
            '</a> ' +
          '</div> ' +
        '</div>'
      );
    });
  }
};

export default Webshop;
