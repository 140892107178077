'use strict';

import GCCarousel from '../vendor/GridColumnCarousel.js';

export default(elem) => {
  let carouselWithInsta = document.querySelector('[data-js-module="instagram-carousel"]'),
      instFeedSettings = {
        hashTag: $('.instagram-feed-data').data('hash-tag'),
        numberOfPhotos: $('.instagram-feed-data').data('number-of-photos')
      };

  function populateInstagramFeed() {
    new Instafeed({
      get: 'user',
      userId: '560396882',
      accessToken: '560396882.98314e0.ee7baa087bcf4983a408b4496665a6bd',
      clientId: '98314e06531f4768a78ed756056f0951',
      resolution: 'standard_resolution',
      limit: instFeedSettings.numberOfPhotos,
      sortBy: 'most-recent',
      filter: function(image) {
          return image.tags.indexOf(instFeedSettings.hashTag) >= 0;
      },
      after: function () {
        var carouselInsta = new GCCarousel({
          elem: carouselWithInsta,
          gridColClasses: 'carousel__page',
          autoplay: true
        })
      },
      template: '<li class="carousel__page">'
                + '<img src="{{image}}"/>'
                + '<span class="caption text--small">{{caption}}</span>'
              + '</li>'
    }).run();
  }

  if($('#instafeed').length === 1) {
      populateInstagramFeed();
    }
}
